<template>
  <div>
    <b-form>
    <div class="projectSettings">
      <div class="projectSettings__title">
        {{$t("projectSettings.title")}}
      </div>
      <Alert
        :message="$t(alertMessage)"
        ref="alert"
      />
      <div class="projectSettings__projectName">
        <div class="projectSettings__subtitle">
          {{$t("projectSettings.name")}}
        </div>
        <input v-model="formData.name" class="projectSettings__projectName--input"/>
        
      </div>

      <div class="projectSettings__projectDescription">
        <div class="projectSettings__subtitle">
          {{$t("projectSettings.description")}}
        </div>
        <textarea v-model="formData.description" class="projectSettings__projectDescription--input"/>
      </div>

      <div class="projectSettings__importAndExportTopic">
        <div class="projectSettings__importAndExportTopic--title">
          {{$t('projectSettings.import')}}
        </div>
        <div class="projectSettings__importTopicWrapper">
          <button type="button" @click.prevent="openModal('import-file')" class="secondary">{{$t('projectSettings.import_topic')}}</button>
          <!-- import file -->
          <AdminModal
            @cancel-action="closeModal"
            @confirm-action="importTopics"
            id="import-file"
            title="admin.modals.upload.file.title"
            :uploadFile="true"
          />
          <div class="projectSettings__label">
            {{$t('projectSettings.import_topic_subtitle')}}
          </div>
        </div>
      </div>

      <div class="projectSettings__importAndExportTopic">
        <div class="projectSettings__importAndExportTopic--title">
          {{$t('projectSettings.export')}}
        </div>
        <div class="projectSettings__importTopicWrapper">
          <button type="button" @click="exportTopic" class="secondary">{{$t('projectSettings.export_topic')}}</button>
          <div class="projectSettings__label">
            {{$t('projectSettings.export_topic_subtitle')}}
          </div>
        </div>
      </div>
      <!-- integration key-->
      <div>
        <div class="projectSettings__subtitle">
          {{$t('projectSettings.vivocha')}}
        </div>
        <div class="projectSettings__description">
            {{$t('projectSettings.vivocha_secret_key')}}
        </div>
        <input placeholder="Vivocha secret key" v-model="formData.integrationKey" class="projectSettings__projectName--input"/>
        <div class="projectSettings__description">
            {{$t('projectSettings.vivocha_project_name')}}
        </div>
        <input placeholder="Vivocha project name" v-model="formData.vivochaProjectName" class="projectSettings__projectName--input"/>
      </div>
      <!-- Active channel-->
      <div class="">
        <div class="projectSettings__wrapper-head">
          <div>
            <div class="projectSettings__subtitle">
              {{$t('projectSettings.channel')}}
            </div>
            <div class="projectSettings__description">
                {{$t('projectSettings.channel_subtitle')}}
            </div>
          </div>

          <div class="projectSettings_table-buttons">
            <span>{{$t('projectSettings.channel_number')}} : {{channelsData.length}}</span>
            <button type="button" class="buttonIcon-plus" @click="openModal('create-channel')"></button>
            <!--create new channel-->
            <AdminModal
              :activeProjectId="activeProjectID"
              @cancel-action="closeModal"
              @confirm-action="createChannel"
              :createChannel="true"
              id="create-channel"
              name="admin.modals.name.label"
              ref="modal_4"
              title="projectSettings.channel_create"
              :type="$t('admin.channel.type')"
            />
            <button type="button" class="buttonIcon-delete" @click="openModal('delete-channel')"></button>
            <!-- delete existing channel -->
            <AdminModal
              :activeProjectId="activeProjectID"
              @cancel-action="closeModal"
              @confirm-action="deleteChannel"
              :deleteChannel="true"
              id="delete-channel"
              name="admin.modals.name.label"
              ref="modal_2"
              title="projectSettings.channel_delete"
              :type="$t('admin.channel.type')"
            />
          </div>
        </div>
        <b-table style="width:98%" borderless striped hover :items="channelsData" :fields="channelFields"></b-table>
      </div>
      <!--Anonymizer-->
      <div>
        <div class="flex-item">
          <div class="projectSettings__subtitle">
            {{$t('projectSettings.anonymizer')}}
          </div>
        </div>
        <div class="projectSettings__description">
          {{$t('projectSettings.anonymizer_subtitle')}}
        </div>
        <!--checkbox list-->
        <div v-for="(item, index) in anonymizationData" :key="index">
          <div class="checkboxList-title">
            {{ item.category_name }}
          </div>
          <div v-for="(item2) in anonymizationData[index].entities" :key="item2.entity_id">
            <b-form-checkbox size="sm" :value="item2.entity_id" v-model="selectedAnonymizationCheckbox">{{ item2.name }}</b-form-checkbox>
          </div>
        </div>
      </div>

      
      
      <!--fixed button-->
      <br>
      <button  @click.prevent="saveChanges" class="primaryXL saveChanges">{{$t('projectSettings.save_changes')}}</button>
      <div>
        <button @click.prevent="openModal('delete-project')" class="delete">{{$t('projectSettings.delete_project')}}</button>
        <!-- Modal to delete the active project. -->
        <AdminModal
          :activeProject="activeProject"
          @cancel-action="closeModal"
          @confirm-action="deleteProject"
          :deleteProject="true"
          id="delete-project"
          ref="modal_2"
          title="admin.modals.delete.title"
        />
      </div>
    </div>
    </b-form>
  </div>
</template>

<script>
import axios from "axios"; 
import {AdminModal, AdminSidebar, Alert} from '@/components';
import _ from "lodash";


export default {
  name: "ProjectSettings",
  components: {
    AdminModal,
    AdminSidebar,
    Alert
  },
  data(){
    return{
      activeProject : {},
      activeProjectID: Number(localStorage.getItem("selectedProjectID")),
      vivochaStreamID: null,
      formData:{
        name:'',
        description:'',
        integrationKey: null,
        vivochaProjectName: null
      },
      initialData:{
        formData: {},
        channelsData: [],
        selectedAnonymizationCheckbox: [],
      },
      changes: [false, false, false, false, false],
      allAlertMessages: { 
        singleChange:  
          [
            'projectSettings.change_name',
            'projectSettings.change_description',
            'projectSettings.change_channel',
            'projectSettings.change_vivocha_integration_key',
            'projectSettings.change_anonymization_entity',
          ],
        multipleChanges: 'projectSettings.change_multiple',
        noChanges: 'projectSettings.change_no'
      },
      channelFields:['name'],
      channelsData: [],
      channelsToDelete : [],

      selectedAnonymizationCheckbox: [],
      allAnonymizationCheckbox: [],
    
      anonymizationData: [],

      alertMessage: ""

    }
  },
  created() {
    this.authorizeUser();
  },
  methods: {
    closeModal(modal) {
      this.$bvModal.hide(modal);
    },
    openModal(modal) {
      this.$bvModal.show(modal);
    },
    /**
     * Get all settings Data
     */
    async getSettingsData() {

      try{
        await axios.get(`/project/${this.activeProjectID}`).then(res => {
            this.activeProject = res.data.data
            this.formData.name = res.data.data.name;
            this.formData.description = res.data.data.description;
          } 
        );
      
      }catch(err){
        console.log(err)
      }

      try{
        await axios.get(`/project/${this.activeProjectID}/streams`).then(res => {
            console.log(res.data.data)
            let vivochaStream = res.data.data.find(obj => {
              return obj.name === 'VIVOCHA_CONV_STREAM'
            })
            if(vivochaStream){
              this.formData.integrationKey = vivochaStream.auth_key
              this.formData.vivochaProjectName = vivochaStream.stream_source_id
              this.vivochaStreamID = vivochaStream.id
            }
          } 
        );
      
      }catch(err){
        console.log(err)
      }

      try{
        await axios.get(`/project/${this.activeProjectID}/anonymization-entities`).then(res => {
            let anon_entities = res.data.data
            let active_entities = _.filter(anon_entities, {status: true})
            this.anonymizationData=  _.chain(anon_entities).groupBy("category").map((value, key) => ({ category_name: value[0].category, entities: value })).value()
            let test = this.selectedAnonymizationCheckbox = _.map(active_entities, 'entity_id');
            this.selectedAnonymizationCheckbox = _.map(active_entities, 'entity_id');
            this.allAnonymizationCheckbox = _.map(anon_entities, 'entity_id');
          } 
        );
      }catch(err){
        console.log(err)
      }

      try{
        await axios.get(`/project/${this.activeProjectID}/channels`).then(res => {
            this.channelsData = res.data.data.channels
            console.log(this.channelsData)
          } 
        );
      }catch(err){
        console.log(err)
      }

      this.initialData.selectedAnonymizationCheckbox = JSON.parse(JSON.stringify(this.selectedAnonymizationCheckbox));
      this.initialData.formData = JSON.parse(JSON.stringify(this.formData));
      this.initialData.channelsData = JSON.parse(JSON.stringify(this.channelsData)); 
    },

    /**
     * Get all settings Data
     */
    async saveChanges() {

      // SAVE ANONYMIAZTION ENTITIES CHANGES 
      let disactiveAnonEntities  = _.xor(this.selectedAnonymizationCheckbox, this.allAnonymizationCheckbox);
      disactiveAnonEntities.forEach(async (entity_id) => {
        try{
          await axios.post(`/project/${this.activeProjectID}/anonymization-entity/${entity_id}`, {status : false});
        }catch(err){
          console.log(err)
        }
      });

      this.selectedAnonymizationCheckbox.forEach(async (entity_id) => {
        try{
          await axios.post(`/project/${this.activeProjectID}/anonymization-entity/${entity_id}`, {status : true});
        }catch(err){
          console.log(err)
        }
      });

      // SAVE ANONYMIAZTION ENTITIES CHANGES 
      this.channelsData.forEach(async (channelObj) => {
        try{

          if(!channelObj.id){
            await axios.put(`/channel`, {project_id : this.activeProjectID, name : channelObj.name });
          }

        }catch(err){
          console.log(err)
        }
      });
      this.channelsToDelete.forEach(async (channelObj) => {
        try{
          if(!channelObj.id){
            await axios.delete(`/channel/${channelObj.id}`);
          }
        }catch(err){
          console.log(err)
        }
      });

      //SAVE INPUT TEXT ITEMS

      try{
        await axios.post(`/project/${this.activeProjectID}`, {
          name: this.formData.name,
          description: this.formData.description
        })
      
      }catch(err){
        console.log(err)
      }
      
      if(this.vivochaStreamID){

        try{
          await axios.post(`/stream/${this.vivochaStreamID}`,{
            auth_key: this.formData.integrationKey,
            stream_source_id: this.formData.vivochaProjectName
          })
        
        }catch(err){
          console.log(err)
        }

      }else if(this.vivochaStreamID  == null && (this.formData.integrationKey != null || this.formData.vivochaProjectName != null)){
        await axios.put(`/project/${this.activeProjectID}/stream`,{
            name: 'VIVOCHA_CONV_STREAM',
            auth_key: this.formData.integrationKey,
            stream_source_id: this.formData.vivochaProjectName
          })
      }

      /* FIND OUT MODIFIES TO show the associated alert*/
      this.changes = [false, false, false, false, false]

      if( this.initialData.formData.name !== this.formData.name)  {this.changes[0] = true}
      if( this.initialData.formData.description !== this.formData.description) {this.changes[1] = true}
      if(!(_.isEqual( this.initialData.channelsData, this.channelsData))){this.changes[2] = true}
      if( (this.initialData.formData.integrationKey !== this.formData.integrationKey) || (this.initialData.formData.vivochaProjectName !== this.formData.vivochaProjectName)  ) {this.changes[3] = true}
      if(!(_.isEqual( this.initialData.selectedAnonymizationCheckbox, this.selectedAnonymizationCheckbox))){this.changes[4] = true}

      var num_changes = _.filter(this.changes, function(c) { if (c === true) return c }).length;
      if(num_changes === 0) {
        this.alertMessage = this.allAlertMessages.noChanges
        this.$refs.alert.showAlert()
      }else if(num_changes > 1){
        this.alertMessage = this.allAlertMessages.multipleChanges
        this.$refs.alert.showAlert()
      }else{
        const isTrue = (element) => element === true;
        let alertIndex = this.changes.findIndex(isTrue);
        this.alertMessage = this.allAlertMessages.singleChange[alertIndex]
        this.$refs.alert.showAlert()
      }
      
      await this.getSettingsData()
      
    },
    
    async createChannel({name}){
      let existentChannel = _.find(this.channelsData, {name: name});
      if(!existentChannel){
        this.channelsData.push({name: name})
      }
      this.closeModal("create-channel")
    },

    async deleteChannel({name}){
      let channelToDelete = _.find(this.channelsData, {name: name});
      if(!channelToDelete.id){
        //function(n) {return n != channelToDeleteIndex}
        this.channelsData = _.filter(this.channelsData, c => c.name != channelToDelete.name);
      }else{
        this.channelsData = _.filter(this.channelsData, c => c.name != channelToDelete.name);
        this.channelsToDelete.push(channelToDelete)
      }
      
      this.closeModal("delete-channel")
    },
    /**
     * Authorize the user by either `window.localStorage', `window.sessionStorage`, or a cookie-stored token as a fallback.
     */
    authorizeUser() {
      let token;
      if (sessionStorage) {
        token = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : sessionStorage.getItem("jwt");
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      } else {
        token = cookies.getCookie("jwt");
      }
     
    },

    async exportTopic() {
      await axios
        .post(`/project/${this.activeProjectID}/action/export-topics`, {
          responseType: "blob"
        })
        .then(res => {
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.setAttribute("download", "topics.csv");
          fileLink.href = fileUrl;
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }, 
  /**
   * Import topics through a modal.
  */
    async importTopics(file) {
      let formData = new FormData();
      formData.append("file", file);
      await axios
        .post(`/project/${this.activeProjectID}/action/import-topics`, formData, {
          headers: {"Content-Type": "multipart/form-data"}
        }).then(() => {
          this.alertMessage= "admin.generate.topic_import";
          this.$refs.alert.showAlert()
          this.closeModal("import-file");
        });
    },

    /**
     * Delete the active project.
     * @param {number} id - ID of the active project.
     */
    async deleteProject(id) {
      await axios
        .delete(`/project/${id}`)
          .then(() => {
            this.closeModal("delete-project");
            location.href='/projects'
          });
    },
    handleLogout() {
      if (localStorage || sessionStorage) {
        localStorage.removeItem("jwt") || sessionStorage.removeItem("jwt");
      } else {
        this.cookies.deleteCookie("jwt");
      }
      this.$store.commit("setAuth", false);
      this.$router.push("/login");
    },
  },
  

  async beforeMount(){
    await this.getSettingsData();
  },

  mounted(){
    this.$root.$on("bv::modal::hide",() => {
      if (this.$refs.modal_4) {
        this.$refs.modal_4.form = {};
      }
      if (this.$refs.modal_2) {
        this.$refs.modal_2.form = {};
      }
    });
  }
};
</script>